<template>
  <div>
    <div v-if="video_ready" :class="loading_video ? 'hidden' : 'block'" class="hls-plyr-player">
      <vue-plyr ref="plyr" :class="playerClass" :options="options" class="player-wrapper">
        <video ref="video" :class="playerClass" :data-poster="poster_url" preload="none"></video>
      </vue-plyr>
    </div>
    <div v-if="loading_video" class="simple-spinner static mt-2">
      <span></span>
    </div>
  </div>
</template>

<script>
import Hls from 'hls.js';

export default {
  name: 'HlsPlayer',
  props: {
    videoId: {
      type: String,
      required: true,
    },
    keyId: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      default: '',
      required: false,
    },
    playerClass: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      hls: null,
      source: null,
      video_ready: false,
      loading_video: true,
      options: {
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
        settings: ['captions', 'quality', 'speed', 'loop'],
        quality: {},
        previewThumbnails: {},
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
        },
        playsinline: true,
      },
      key: 0,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    apple_playback() {
      return document.createElement('video').canPlayType('application/vnd.apple.mpegURL');
    },
    local_storage_key() {
      return `${this.activeUserInfo.id}-${this.activeCourseInfo.id}-${this.keyId}-${this.videoId}`;
    },
    player() {
      return this.$refs.plyr.player;
    },
    poster_url() {
      return `https://app-cdn.rapidseminars.com/thumbnails/${this.poster}.jpeg`;
    },
    video() {
      return this.$refs.video;
    },
  },
  methods: {
    durationSetSavedTime() {
      const time = window.localStorage.getItem(this.local_storage_key);
      if (time) {
        this.video.currentTime = time;
      }
    },
    loadAlternateSource(apple) {
      return new Promise((resolve) => {
        this.video_ready = true;
        resolve();
      }).then(() => {
        this.durationSetSavedTime();
        this.video.src = this.source;
        if (apple) this.video.type = 'application/vnd.apple.mpegURL';
        document.getElementsByClassName('plyr__poster')[0].className += ` ${this.playerClass}`;
        setTimeout(() => {
          this.setupPlayer();
          this.loading_video = false;
        }, 100);
      });
    },
    initHlsVideo(hls) {
      return new Promise((resolve) => {
        this.video_ready = true;
        resolve();
      }).then(() => {
        this.durationSetSavedTime();
        hls.attachMedia(this.video);
        this.$once('hook:beforeDestroy', () => {
          hls.stopLoad();
          hls.destroy();
        });
        document.getElementsByClassName('plyr__poster')[0].className += ` ${this.playerClass}`;
        setTimeout(() => {
          this.setupPlayer();
          this.loading_video = false;
        }, 1300);
      });
    },
    initProcess() {
      const hls = new Hls();

      if (Hls.isSupported()) {
        this.hls = hls;
        hls.loadSource(this.source);
        const self = this;
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          const availableQualities = hls.levels.map((l) => l.height);
          const index = availableQualities[availableQualities.indexOf(1080)];
          self.options.quality = {
            default: index,
            options: availableQualities,
            forced: true,
            onChange: (e) => self.updateQuality(e),
          };
          self.initHlsVideo(hls);
        });
        return;
      }

      if (this.apple_playback) {
        return this.loadAlternateSource(true);
      }

      if (!Hls.isSupported()) {
        return this.loadAlternateSource();
      }
    },
    setupPlayer() {
      const self = this;
      this.player.on('play', function () {});
      this.player.on('pause', function () {});
      this.player.on('qualitychange', function () {});
      this.player.on('stalled', function () {});
      this.player.on('error', function () {});
      this.player.on('ended', function () {});
      this.player.on('timeupdate', function () {
        if (self.video.currentTime !== 0) {
          self.updateCurrentTime(self.video.currentTime);
        }
      });
    },
    updateCurrentTime(time) {
      if (this.video.duration && this.video.duration - time < 30) {
        return window.localStorage.setItem(this.local_storage_key, 0);
      }
      if (this.video.duration && time < 15) {
        return window.localStorage.setItem(this.local_storage_key, 0);
      }
      window.localStorage.setItem(this.local_storage_key, time);
    },
    updateQuality(newQuality) {
      const newQualityParsed = parseInt(newQuality);
      this.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQualityParsed) {
          this.hls.currentLevel = levelIndex;
        }
      });
    },
  },
  beforeMount() {
    this.source = `https://get-video-academy.intellectualpoint.com/${this.videoId}`;
    this.initProcess();
  },
};
</script>
<style>
@import 'vue-plyr.css';

.plyr__poster {
  background-color: transparent;
  background-size: cover;
}

.plyr__video-wrapper {
  z-index: -1;
}

/*
.plyr__video-wrapper::before {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
  content: '';
  height: 37.5%;
  width: 37.5%;
  max-height: 40rem;
  max-width: 40rem;
  background-size: 37.5% auto, auto;
}
 */
</style>
